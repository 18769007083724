import { Card, Dropdown, Image } from "react-bootstrap";
import SidebarAdmin from "./sidebar";

export default function HeaderAdmin({ setSelectedAdvert, adverts }) {
  return (
    <Card style={{backgroundColor: "#4caf50"}}>
      <Card.Body className="p-2" >
        <div className="d-flex justify-content-between ">
          
          <SidebarAdmin setSelectedAdvert={setSelectedAdvert} adverts={adverts} />

          <Dropdown drop="hidde">
            <Dropdown.Toggle variant="outline-light" id="dropdown-basic" className="dropdown-toggle-hide-arrow" >
                <span className="fw-bold me-1">Admin</span>
                <Image height={22} src="https://cdn-icons-png.flaticon.com/512/4675/4675159.png" roundedCircle />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item className="text-center">
                <Image height={60} src="https://cdn-icons-png.flaticon.com/512/4675/4675159.png" roundedCircle />
                <br />
                <span>Admin</span>
              </Dropdown.Item>
              <Dropdown.Item href="/admin" className="text-center">logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Body>
    </Card>
  )
}