import React from "react";
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCategory } from "../../hooks/category/use-category";
import { useAllPosts } from "../../hooks/use-posts";
import PostCards from "../PostCards/PostCards";
import "./Post.css";

function Posts() {
  const { allPosts } = useAllPosts();
  const { womanPosts, menPosts, accessoriesPosts, booksPosts, eletronicPosts } =
    useCategory();

  const isBigScreen = useMediaQuery({ query: "(min-width: 995px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 520px)" });

  const newsCards = allPosts.map((product, index) => {
    if (index < 5) {
      return (
        <SwiperSlide key={index} className="fresh-recomendation-cards">
          <PostCards product={product} index={index} />
        </SwiperSlide>
      );
    }
    return null;
  });

  const allPostsCards = allPosts.map((product, index) => {
    return (
      <SwiperSlide key={index} className="fresh-recomendation-cards">
        <PostCards product={product} index={index} />
      </SwiperSlide>
    );
  });

  const womanCards = womanPosts.map((product, index) => {
    return (
      <SwiperSlide key={index} className="fresh-recomendation-cards">
        <PostCards product={product} index={index} />
      </SwiperSlide>
    );
  });

  const menCards = menPosts.map((product, index) => {
    return (
      <SwiperSlide key={index} className="fresh-recomendation-cards">
        <PostCards product={product} index={index} />
      </SwiperSlide>
    );
  });

  const eletronicCards = eletronicPosts.map((product, index) => {
    return (
      <SwiperSlide key={index} className="fresh-recomendation-cards">
        <PostCards product={product} index={index} />
      </SwiperSlide>
    );
  });

  const accessoriesCards = accessoriesPosts.map((product, index) => {
    return (
      <SwiperSlide key={index} className="fresh-recomendation-cards">
        <PostCards product={product} index={index} />
      </SwiperSlide>
    );
  });

  const booksCards = booksPosts.map((product, index) => {
    return (
      <SwiperSlide key={index} className="fresh-recomendation-cards">
        <PostCards product={product} index={index} />
      </SwiperSlide>
    );
  });

  return (
    <div className="postParentDiv">
      <div className="container-section">
        <h4>Novidades</h4>
        {newsCards.length > 0 ? (
          <div className="container">
            <Link to="./viewmore">
              <p style={{ width: "100%", textAlign: "end" }}>Veja mais</p>
            </Link>
            <Swiper
              modules={[Pagination, FreeMode]}
              slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
              navigation
              pagination={{ clickable: true, dynamicBullets: true }}
              freeMode={true}
            >
              {newsCards}
            </Swiper>
          </div>
        ) : (
          <p className="product-not-found">Nenhum produto encontrado.</p>
        )}
      </div>

      <div className="container-section" id="womanPosts">
        <h4>Roupas Femininas</h4>
        {womanCards.length > 0 ? (
          <div className="container">
            <Swiper
              modules={[Pagination, FreeMode]}
              slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
              navigation
              pagination={{ clickable: true, dynamicBullets: true }}
              freeMode={true}
            >
              {womanCards}
            </Swiper>
          </div>
        ) : (
          <p className="product-not-found">Nenhum produto encontrado.</p>
        )}
      </div>

      <div className="container-section" id="menPosts">
        <h4>Roupas Masculinas</h4>
        {menCards.length > 0 ? (
          <div className="container">
            <Swiper
              modules={[Pagination, FreeMode]}
              slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
              navigation
              pagination={{ clickable: true, dynamicBullets: true }}
              freeMode={true}
            >
              {menCards}
            </Swiper>
          </div>
        ) : (
          <p className="product-not-found">Nenhum produto encontrado.</p>
        )}
      </div>

      <div className="container-section" id="accessories">
        <h4>Acessórios</h4>
        {accessoriesCards.length > 0 ? (
          <div className="container">
            <Swiper
              modules={[Pagination, FreeMode]}
              slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
              navigation
              pagination={{ clickable: true, dynamicBullets: true }}
              freeMode={true}
            >
              {accessoriesCards}
            </Swiper>
          </div>
        ) : (
          <p className="product-not-found">Nenhum produto encontrado.</p>
        )}
      </div>

      <div className="container-section" id="eletronic">
        <h4>Eletrônicos</h4>
        {eletronicCards.length > 0 ? (
          <div className="container">
            <Swiper
              modules={[Pagination, FreeMode]}
              slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
              navigation
              pagination={{ clickable: true, dynamicBullets: true }}
              freeMode={true}
            >
              {eletronicCards}
            </Swiper>
          </div>
        ) : (
          <p className="product-not-found">Nenhum produto encontrado.</p>
        )}
      </div>

      <div className="container-section" id="books">
        <h4>Livros</h4>
        {booksCards.length > 0 ? (
          <div className="container">
            <Swiper
              modules={[Pagination, FreeMode]}
              slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
              navigation
              pagination={{ clickable: true, dynamicBullets: true }}
              freeMode={true}
            >
              {booksCards}
            </Swiper>
          </div>
        ) : (
          <p className="product-not-found">Nenhum produto encontrado.</p>
        )}
      </div>

      <div className="container-section" id="others">
        <h4>Outros</h4>
        {allPostsCards.length > 0 ? (
          <div className="container">
            <Link to="./viewmore">
              <p style={{ width: "100%", textAlign: "end" }}>Veja mais</p>
            </Link>
            <Swiper
              modules={[Pagination, FreeMode]}
              slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
              navigation
              pagination={{ clickable: true, dynamicBullets: true }}
              freeMode={true}
            >
              {allPostsCards}
            </Swiper>
          </div>
        ) : (
          <p className="product-not-found">Nenhum produto encontrado.</p>
        )}
      </div>
    </div>
  );
}

export default Posts;
