import { get } from "react-hook-form";
import { create } from "zustand";

export const useAllUsersTalked = create((set) => ({
  allUsersTalked: [],
  lastMessageOfMyUsers: {},

  setAllUsersTalked: (value) => set(() => ({ allUsersTalked: value })),

  setLastMessageOfMyUsers: (userExternId, content) =>
    set((state) => ({
      lastMessageOfMyUsers: {
        ...state.lastMessageOfMyUsers,
        [userExternId]: [{ fromUserId: userExternId, lastMessage: content }],
      },
    })),
  getLastMessageOfMyUsers: (userExternId) =>
    get().lastMessageOfMyUsers[userExternId] || [],
}));
