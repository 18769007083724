import { create } from "zustand";

export const useModalDelete = create((set) => ({
  modalShow: false,
  productId: "",
  pendingCard: false,
  setModalShow: (value) => set(() => ({ modalShow: value })),
  setProductId: (value) => set(() => ({ productId: value })),
  setPendingCard: (value) => set(() => ({ pendingCard: value })),
}));
