import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { aproveAds, getAds, rejectAds } from "../../../services/api-admin";
import CarouselsImages from "./carrousell";
import ConfirmationModal from "./confirmation-modal";
import HeaderAdmin from "./header";

export default function AdminMain() {
  const history = useHistory()
  const [adverts, setAdverts] = useState()
  const [selectedAdvert, setSelectedAdvert] = useState()

  const getAdvertments = useCallback(() => {
    getAds().then(response => {
      const data = response.data.items.map(item => ({
        id: item.id,
        images: item.imagens.map(image => {return image.url}),
        title: item.nome,
        description: item.descricao,
        name: item.nomeVendedor,
        date: item.postadoEm,
        email: item.emailVendedor,
        formatedPrice: item.preco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }))

      setSelectedAdvert(data[0])
      setAdverts(data)
    }).catch(error => {
      alert(error)
      console.error("Error na requisição: ", error)
    })
  }, [setSelectedAdvert])

  const aproveAdvert = (id) => {
    aproveAds(id).then(res => {
      getAdvertments()
    }).catch(err => {
      console.error(err)
    })
  }
  
  const rejectAdvert = (id) => {
    rejectAds(id).then(res => {
      getAdvertments()
    }).catch(err => {
      console.error(err)
    })    
  }

  useEffect(() => {
    getAdvertments()
  }, [getAdvertments])

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (!token || token === "" || token === " ") history.push('/admin')
  }, [history])

  return (
    <div style={{backgroundColor: "#fff", height: "100dvh"}} className="p-3">
      <HeaderAdmin setSelectedAdvert={setSelectedAdvert} adverts={adverts} />

      <div className="mt-4 pb-3 hidden-scroll" style={{height: "calc(100dvh - 6rem)"}}>
        <div className="d-flex justify-content-between">
          <h5 className="text-secondary">
            {selectedAdvert?.name ?? " -- "} <br />
            <span className="fs-6 fw-normal">{selectedAdvert?.email ?? ""}</span>
          </h5>
          <h5 className="text-secondary">
            {selectedAdvert?.date ? moment(selectedAdvert?.date).format("DD/MM/YYYY") : " -- / -- / --"}
          </h5>
        </div>

        <Card body className="mb-3">
          { selectedAdvert?.images && 
            <CarouselsImages imagesList={selectedAdvert?.images} />
          }
        </Card>


        <Card body>
          <div className="d-flex justify-content-between">
            <div>
              <h3 className="text-secondary">Sobre o anúncio</h3>
              <span className="text-secondary">#ID ({selectedAdvert?.id})</span>
            </div>
            <h4 className="text-secondary">{selectedAdvert?.formatedPrice}</h4>
          </div>
          <div className="mt-3">
            <label className="fw-bold">Titulo:</label>
            <Card className="p-2">
              {selectedAdvert?.title ?? " -- "}
            </Card>
          </div>
          <div className="mt-3">
            <label className="fw-bold">Descrição:</label>
            <Card className="p-2"> 
              {selectedAdvert?.description ?? " -- "}
            </Card>
          </div>
        </Card>
        
        <div className="pt-2 text-end">
          <ConfirmationModal
            color="danger" 
            title="Recusar anúncio" 
            description={`Deseja recusar e excluir o anúncio? <br> ID: ${selectedAdvert?.id}`}
            buttonLabel="Recusar"
            className="me-2"
            onConfirm={() => rejectAdvert(selectedAdvert?.id)}
          />
          <ConfirmationModal 
            color="success" 
            title="Aprovar anúncio" 
            description={`Deseja aprovar e publicar o anúncio? <br> ID: ${selectedAdvert?.id}`} 
            buttonLabel="Aprovar"
            onConfirm={() => aproveAdvert(selectedAdvert?.id)}
          />
        </div>
      </div>
    </div>
  )
}