import React from "react";

import "./Footer.css";

function Footer() {
  return (
    <div className="footerParentDiv">
      <div className="footer">
        <p>Todos os direitos reservados aos alunos de Ciência da Computação e Análise e Dev de Sistemas da Unifor 2024.1</p>
      </div>
    </div>
  );
}

export default Footer;
