import React, { useState } from "react";
import "./pagination.css";

function Pagination({ currentPage, setCurrentPage, totalPages }) {
  let [activeItem, setActiveItem] = useState(false);

  const showNextPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActiveItem(pageNumber);
  };

  let pagination = Array.from({ length: totalPages }, (_, i) => i + 1).map(
    (pageNumber) => {
      const initialPage = currentPage === pageNumber;
      
      return (
        <div
          key={pageNumber}
          id={pageNumber}
          onClick={() => showNextPage(pageNumber)}
        >
          <div
            className={
              (pageNumber === activeItem  || initialPage) ? "active-pagination" : "not-active"
            }
          >
            {pageNumber}
          </div>
        </div>
      );
    }
  );

  return <div className="pagination-items-position">{pagination}</div>;
}

export default Pagination;
