import axios from "axios";
import { host } from "./service";

export const loginAdmin = (email, password) => {
  const data = { email, password };

  return axios.post(`${host}/api/user/login`, data);
};

export const getAds = () => {
  const token = localStorage.getItem("adminToken");

  if (token) {
    return axios({
      method: "get",
      url: `${host}/api/Admin/0`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const aproveAds = (id) => {
  const token = localStorage.getItem("adminToken");

  if (token) {
    return axios({
      method: "post",
      url: `${host}/api/Admin/avaliar`,
      data: {
        id,
        aprovado: true,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const rejectAds = (id) => {
  const token = localStorage.getItem("adminToken");
  
  if (token) {
    return axios({
      method: "delete",
      url: `${host}/api/Admin/delete/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
