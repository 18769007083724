import React from 'react';
import AdminLogin from '../../Components/admin/login/Login';

function LoginPage() {
  return (
    <div>
      <AdminLogin />
    </div>
  );
}

export default LoginPage;
