import axios from "axios";
import { host } from "./service";

export const getRecentChats = () => {
  const token = localStorage.getItem("authToken");

  return axios.get(`${host}/api/Chat/recentchats`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCurrentChat = (chatId, index = 1) => {
  const token = localStorage.getItem("authToken");

  return axios.get(`${host}/api/Chat/${chatId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { index: index },
  });
};

export const createNewChat = (receiverId) => {
  const token = localStorage.getItem("authToken");

  return axios({
    method: "post",
    url: `${host}/api/Chat/${receiverId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUnreadMessages = (chatId) => {
  const token = localStorage.getItem("authToken");

  return axios.patch(
    `${host}/api/Chat/resetUnread`, 
    JSON.stringify({ chatId }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
