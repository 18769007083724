import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./CreateModal.css";

function CreateModal({ show, handleClose }) {
  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Produto cadastrado!</Modal.Title>
        </Modal.Header>
        <div className="content">
          <Modal.Body>
            Seu produto foi cadastrado com <strong>sucesso</strong>! Aguarde a {" "}
            <strong>validação</strong> de nossa equipe para exibirmos seu
            anúncio no site!
          </Modal.Body>
          <Modal.Footer
            style={{
              paddingTop: "0.2em",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Button onClick={handleClose} variant="success">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default CreateModal;
