import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function ConfirmationModal({color, title, description, buttonLabel, className, onConfirm}) {
  const [show, setShow] = useState(false);

  const handleClose = (canConfirm) => {
    if(canConfirm) onConfirm()
    setShow(false)
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant={color} onClick={handleShow} className={className} >
        {buttonLabel}
      </Button>

      <Modal bg show={show} onHide={handleClose}>
        <Modal.Header closeButton  className={`bg-${color}`} >
          <Modal.Title className={`${color && 'text-white'}`}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{
            __html: description
          }} />
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={() => handleClose(false)}>
            Cancelar
          </Button>
          <Button type="button" variant={color ?? "primary"} onClick={() => handleClose(true)}>
            {buttonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}