import React, { useEffect, useState } from "react";
import { getItemPerPage } from "../../services/api-item";
import Pagination from "../Pagination/Pagination";
import PostCards from "../PostCards/PostCards";
import "./allposts.css";

function AllPosts() {
  const [currentPage, setCurrentPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [pages, setPages] = useState();

  useEffect(() => {
    const getCurrentPage = async () => {
      const itemsPage = await getItemPerPage(currentPage, 8);
      setPosts(itemsPage.data.items);
      setPages(itemsPage.data.pages);
    };

    getCurrentPage();
  }, [currentPage]);

  return (
    <div className="container-all-parent">
      {posts.length > 0 ? (
        <>
          <div className="display-all-parent">
            {posts &&
              posts.map((product, index) => (
                <PostCards product={product} index={index} />
              ))}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={pages}
          />
        </>
      ) : (
        <div className="product-not-found-view">
          <p>Nenhum produto encontrado!</p>
        </div>
      )}
    </div>
  );
}

export default AllPosts;
