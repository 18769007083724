import { XIcon } from "lucide-react";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useHistory } from "react-router-dom";
import { useModalDelete } from "../../hooks/use-modalDelete";
import "./PostCards.css";

function PostCards({
  product,
  index,
  cardOfUser = false,
  pendingCard = false,
  w = "20em"
}) {
  const { setModalShow, setProductId, setPendingCard } = useModalDelete();
  const history = useHistory();

  const onProductDelete = (productId) => {
    setProductId(productId);
    setPendingCard(pendingCard);
    setModalShow(true);
  };

  return (
    <Card
      style={{
        width: w,
        height: "23em",
        margin: "5px",
        borderColor: "#ebeeef",
        position: "relative",
        borderRadius: "1rem",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
      className={pendingCard && "pendind-card"}
      index={index}
    >
      <Card.Img
        style={{
          height: "40%",
          objectFit: "cover",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
        }}
        variant="top"
        src={product?.imagemPrincipal.url}
        key={product?.imagemPrincipal.id}
      />
      <Card.Body
        style={{
          height: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <>
          <Card.Title style={{ fontWeight: 500, fontSize: "18px" }}>
            {product?.nome.length > 20
              ? `${product?.nome.substring(0, 20)}...`
              : product?.nome}
          </Card.Title>
          <Card.Text>
            {product?.preco.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </Card.Text>
        </>
        <Card.Text className="card-text-p">
          {product?.descricao.length > 20
            ? `${product?.descricao.substring(0, 20)}...`
            : product?.descricao}
        </Card.Text>
        <Button
          variant="primary"
          onClick={() => {
            history.push(`/viewpost/${product?.id}`);
          }}
        >
          Abrir anúncio
        </Button>
        {cardOfUser && (
          <div className="delete-card">
            <Button
              onClick={() => onProductDelete(product?.id)}
              variant="danger"
              className="btn-delete"
              size="sm"
            >
              <XIcon size={15} />
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default PostCards;
