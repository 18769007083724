import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../hooks/use-auth";
import { validateLogin } from "../../services/api-user";
import "./Login.css";

function Login({ show, handleShow }) {
  const { setUser } = useAuth();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const showToastMessage = (message) => {
    toast.error(`${message}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    validateLogin(emailRef.current.value, passwordRef.current.value)
      .then((response) => {
        setUser(response.data.user);
        localStorage.setItem("authToken", String(response.data.token));
        handleShow();
        window.location.href = "/";
      })
      .catch(() => {
        showToastMessage(
          "E-mail ou senha incorretos. Revise as informações e tente novamente."
        );
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header className="modal-header">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="title-modal"
          >
            Login
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="login-body">
          <div>
            <Form.Label className="label-modal">E-mail</Form.Label>
            <Form.Control
              ref={emailRef}
              className="input-active"
              required
              type="email"
              placeholder="Digite seu e-mail"
              name="email"
            />
          </div>
          <div>
            <Form.Label className="label-modal">Senha</Form.Label>
            <Form.Control
              ref={passwordRef}
              className="input-active"
              required
              type="password"
              name="password"
              placeholder="Digite sua senha"
            />
          </div>
          <Link to="/signup">
            Não possui uma conta ainda? Cadastre-se agora!
          </Link>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-modal">
            <Button variant="secondary" onClick={handleShow}>
              Fechar
            </Button>
            <Button onClick={handleSubmit}>Entrar</Button>
          </div>
        </Modal.Footer>
      </Form>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Modal>
  );
}

export default Login;
