import { Carousel, Image } from "react-bootstrap";

export default function CarouselsImages({ imagesList, blankImage }) {
  return (
    <Carousel>
      { imagesList.length ?
        imagesList.map((image, index) => (
        <Carousel.Item key={index} className="text-center rounded p-3" style={{backgroundColor: "#ededed", height: "22rem"}}>
          <Image className="h-100" style={{maxWidth: "100%"}} src={image} rounded />
        </Carousel.Item>
        )) : blankImage && 
        <Carousel.Item className="text-center rounded p-3" style={{backgroundColor: "#ededed", height: "22rem"}}>
          <Image className="h-100" style={{maxWidth: "100%"}} src={blankImage} rounded />
        </Carousel.Item>
      }
    </Carousel>
  );
}
