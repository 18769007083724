import React from "react";
import { useParams } from "react-router-dom";
import ChatFloatingButton from "../Components/ChatFloatingButton/ChatFloatingButton";
import Header from "../Components/Header/Header";
import View from "../Components/View/View";

function ViewPost() {
  const { productId } = useParams();
  return (
    <>
      <Header />
      <View productId={productId} />
      <ChatFloatingButton />
    </>
  );
}

export default ViewPost;
