import React, { useEffect, useRef, useState } from "react";
import { Button, Input, MessageBox } from "react-chat-elements";
import { useConnection } from "../../../hooks/Chat/use-connection";
import { useMessageCurrent } from "../../../hooks/Chat/use-messageCurrent";
import { useAuth } from "../../../hooks/use-auth";
import { getCurrentChat } from "../../../services/api-chat";
import "./ChatPrivate.css";

function ChatPrivate() {
  const [messageCheck, setMessageCheck] = useState([]);
  const [pastMessagesUpdate, setPastMessagesUpdate] = useState([]);
  const [pagesCurrent, setPagesCurrent] = useState(2);
  const [fetching, setFetching] = useState(true);

  let inputRef = useRef(null);

  const scrollRef = useRef(null);
  const { user } = useAuth();
  const {
    currentUserName,
    currentIdChat,
    messagesFromDb,
    currentIdUserExtern,
    page,
  } = useMessageCurrent();
  const { getMessages, connections, messages } = useConnection();

  useEffect(() => {
    scrollToBottom();
    setMessageCheck(getMessages(currentIdChat));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    const currentScrollRef = scrollRef.current;

    if (currentScrollRef)
      currentScrollRef.addEventListener("scroll", fetchMoreMessages);

    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener("scroll", fetchMoreMessages);
      }
    };

    // return async () => {
    //   if (currentScrollRef) {
    //     currentScrollRef.removeEventListener("scroll", handleScroll);
    //   }

    //     connections.invoke("LeaveChat", currentIdUserExtern);

    //     await updateUnreadMessages(currentIdChat)
    //       .then(() => console.log("Success!"))
    //       .catch((error) => console.log("problem " + error.message));
    //   };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef.current]);

  const fetchMoreMessages = async () => {
    if (
      messagesFromDb.length >= 29 &&
      page > 1 &&
      pagesCurrent <= page &&
      scrollRef.current.scrollTop === 0 &&
      fetching
    ) {
      const newPastMessages = await getCurrentChat(currentIdChat, pagesCurrent);
      setPastMessagesUpdate(
        newPastMessages.data.items.reverse(),
        ...pastMessagesUpdate
      );
      setPagesCurrent(pagesCurrent + 1);

      if (pagesCurrent === page) setFetching(false);
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async () => {
    try {
      if (inputRef.current.value.trim() !== "") {
        await connections.invoke("SendMessageToChat", {
          ToChatId: currentIdChat,
          FromUserId: user.id,
          Content: inputRef.current.value,
          ToUserId: currentIdUserExtern,
        });

        scrollToBottom();

        if (inputRef.current) {
          inputRef.current.value = "";
        }
      }
    } catch (error) {
      console.log("error when sending a message: " + error.message);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="container-message">
      {currentUserName ? (
        <>
          <Input
            referance={inputRef}
            placeholder="Escreva aqui..."
            multiline={false}
            className="input-container"
            onKeyPress={handleKeyPress}
            rightButtons={
              <Button
                color="white"
                backgroundColor="black"
                text="Enviar"
                onClick={handleSendMessage}
              />
            }
          />
          <div className="container-message-box" ref={scrollRef}>
            {pastMessagesUpdate.length > 1 &&
              pastMessagesUpdate.map((pastMessage, index) => {
                return (
                  <MessageBox
                    key={index}
                    title={
                      pastMessage?.senderName === user.nome
                        ? user.nome
                        : pastMessage?.senderName
                    }
                    position={
                      pastMessage?.senderName === user.nome ? "right" : "left"
                    }
                    type="text"
                    text={pastMessage?.content}
                  />
                );
              })}
            {messagesFromDb.length > 1 &&
              messagesFromDb.map((pastMessage, index) => {
                return (
                  <MessageBox
                    key={index}
                    title={
                      pastMessage?.senderName === user.nome
                        ? user.nome
                        : pastMessage?.senderName
                    }
                    position={
                      pastMessage?.senderName === user.nome ? "right" : "left"
                    }
                    type="text"
                    text={pastMessage?.content}
                  />
                );
              })}
            {messageCheck.map((chatConnected, index) => {
              return (
                <MessageBox
                  key={index}
                  title={
                    chatConnected?.senderName === user.nome
                      ? user.nome
                      : chatConnected?.senderName
                  }
                  position={
                    chatConnected?.senderName === user.nome ? "right" : "left"
                  }
                  type="text"
                  text={chatConnected.message}
                />
              );
            })}
          </div>
          <div className="container-name">
            <h3>{currentUserName ? currentUserName : ""}</h3>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default ChatPrivate;
