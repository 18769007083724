import React from "react";
import ChatFloatingButton from "../Components/ChatFloatingButton/ChatFloatingButton";
import Header from "../Components/Header/Header";
import AllPosts from "../Components/ViewMoreAllPosts/AllPosts";

function ViewMore() {
  return (
    <>
      <Header />
      <AllPosts />
      <ChatFloatingButton />
    </>
  );
}

export default ViewMore;
