import React from "react";

import Banner from "../Components/Banner/Banner";
import Header from "../Components/Header/Header";

import { useEffect } from "react";
import ChatFloatingButton from "../Components/ChatFloatingButton/ChatFloatingButton";
import Footer from "../Components/Footer/Footer";
import Information from "../Components/Information/Information";
import BarLoadingFullPage from "../Components/Loading/BarLoadingFullPage";
import Posts from "../Components/Posts/Posts";
import { useCategory } from "../hooks/category/use-category";
import { useLoading } from "../hooks/use-loading";
import { usePage } from "../hooks/use-page";
import { useAllPosts } from "../hooks/use-posts";
import { getItemPerCategory, getItemPerPage } from "../services/api-item";

const categorysNames = [
  "Roupas Femininas",
  "Roupas Masculinas",
  "Acessorios",
  "Eletronicos",
  "Livros",
  "Instrumentos",
];

function Home(props) {
  const { loadingPage, setLoadingPage } = useLoading();
  const { setPages } = usePage();
  const { allPosts, setAllPosts } = useAllPosts();
  const {
    setWomanPosts,
    setMenPosts,
    setAccessoriesPosts,
    setBooksPosts,
    setEletronicPosts,
  } = useCategory();

  useEffect(() => {
    setLoadingPage(true);
    const fetchData = async () => {
      try {
        const requests = categorysNames.map(async (category) => {
          try {
            const response = await getItemPerCategory(category);
            switch (category) {
              case "Roupas Femininas":
                setWomanPosts(response.data.items);
                break;
              case "Roupas Masculinas":
                setMenPosts(response.data.items);
                break;
              case "Acessorios":
                setAccessoriesPosts(response.data.items);
                break;
              case "Livros":
                setBooksPosts(response.data.items);
                break;
              case "Eletronicos":
                setEletronicPosts(response.data.items);
                break;
              default:
                break;
            }
          } catch (error) {
            console.error("error when fetching data(HOME): ", error.message);
          }
        });

        await Promise.all(requests);

        if (!allPosts.length > 0) {
          getItemPerPage(1)
            .then((response) => {
              setPages(response.data.pages);
              setAllPosts(response.data.items);
            })
            .catch((error) => console.log(error.message));
        }
      } catch (error) {
        console.error("error when fetching data(HOME): ", error.message);
      } finally {
        setLoadingPage(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header />
      <Banner />
      <Posts />
      <Information />
      <Footer />
      <ChatFloatingButton />
      {loadingPage && <BarLoadingFullPage />}
    </div>
  );
}

export default Home;
