import React from "react";
import ChatFloatingButton from "../Components/ChatFloatingButton/ChatFloatingButton";
import Create from "../Components/Create/Create";
import Header from "../Components/Header/Header";

const CreatePage = () => {
  return (
    <>
      <Header />
      <Create />
      <ChatFloatingButton />
    </>
  );
};

export default CreatePage;
