import React from "react";
import { UserCircle2Icon } from "lucide-react";
import "./InfoUser.css";
import { useAuth } from "../../hooks/use-auth";

function InfoUser() {
  const { user } = useAuth();

  return (
    <div className="container-info">
      <h4 style={{ color: "#0b5ed7" }}>Minha Conta</h4>
      <div className="info-user">
        <div className="info-user-style">
          <UserCircle2Icon size={100} color="#0b5ed7" className="user-circle-icon" />
          <div className="user-details">
            <p>
              <span>Nome:</span> {user?.nome}
            </p>
            <p>
              <span>E-mail:</span> {user?.email}
            </p>
            <p>
              <span>Contato:</span> {user?.contato}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoUser;
