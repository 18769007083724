import React from 'react';
import AdminMain from '../../Components/admin/home/main';

function HomeAdmin() {
  return (
    <AdminMain />
  );
}

export default HomeAdmin;
