import axios from "axios";
import { host } from "./service";

export const createProduct = (
  nome,
  descricao,
  preco,
  aceitaTroca,
  foto,
  subCategoria,
  mostrarContato
) => {
  const token = localStorage.getItem("authToken");

  const data = new FormData();

  data.append("nome", nome);
  data.append("descricao", descricao);
  data.append("preco", preco);
  data.append("aceitaTroca", aceitaTroca);

  foto.forEach((element) => {
    data.append("foto", element);
  });

  data.append("subCategoria", subCategoria);
  data.append("mostrarContato", mostrarContato);

  if (token) {
    return axios.post(`${host}/api/Item`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const getItemPerId = (itemId) => {
  return axios.get(`${host}/api/Item/${itemId}`);
};

export const getItemPerPage = (pagina, pageSize = 10, search = "") => {
  return axios.get(`${host}/api/Item/itens/${pagina}`, {
    params: { search: search, pageSize: pageSize },
  });
};

export const getItemPerCategory = (category, pagina = 1, pageSize = 10) => {
  return axios.get(`${host}/api/Item/categorias/${category}`, {
    params: { pagina: pagina, pageSize: pageSize },
  });
};

export const getItemPerUser = (user) => {
  if (user.id) {
    return axios.get(`${host}/api/Item/user/${user.id}`);
  }
};

export const getItemPerPendingUser = () => {
  const token = localStorage.getItem("authToken");
  
  if (token) {
    return axios.get(`${host}/api/Item/pendentes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const getStatusItemUser = (user) => {
  if (user) {
    return axios.get(`${host}/api/Item/user/${user.id}`);
  }
};

export const getSoldItemPerUser = (user) => {
  if (user) {
    return axios.get(`${host}/api/Venda/${user.id}`);
  }
};

export const modifyItem = (
  item,
  novoNome,
  novaDescricao,
  novoPreco,
  aceitaTroca,
  novaFoto = "url_foto"
) => {
  const data = { novoNome, novaDescricao, novoPreco, aceitaTroca, novaFoto };

  return axios({
    method: "put",
    url: `${host}/api/Item/${item.id}`,
    data: data,
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteItem = (itemId) => {
  const token = localStorage.getItem("authToken");

  return axios.delete(`${host}/api/Item/${itemId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const setSoldItemOfUser = (itemId) => {
  const token = localStorage.getItem("authToken");

  if (token) {
    return axios({
      method: "post",
      url: `${host}/api/Venda/${itemId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
