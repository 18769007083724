import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./CreateModal.css";

function NotLoggedModal({ show, handleClose }) {
  return (
    <Modal show={show} backdrop="static" >
      <Modal.Header>
        <Modal.Title className="user-loged">
          Usuário não cadastrado!
        </Modal.Title>
      </Modal.Header>
      <div className="content">
        <Modal.Body>
          Você não está cadastrado em nosso site! Crie sua conta agora{" "}
          <a href="/signup" target="_blank">
            aqui!
          </a>
        </Modal.Body>
        <Modal.Footer
          style={{
            paddingTop: "0.2em",
            justifyContent: "end",
            display: "flex",
          }}
        >
          <Button onClick={handleClose} variant="warning">
            Confirmar
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default NotLoggedModal;
