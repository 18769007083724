import { Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./Banner.css";
import { useMediaQuery } from "react-responsive";

function BannerHeader() {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  return (
    <>
      {!isMobile ? (
        <Carousel
          style={{ paddingRight: "5em", paddingLeft: "5em" }}
          indicators
          controls={true}
          className="banner-header"
        >
          <Carousel.Item className="carousel-item">
            <img
              src="../../../banner-header/banner1.jpg"
              className="banner-image"
              alt="banner-one"
            />
            <Carousel.Caption className="carousel-caption">
              <h2 className="titulo_fundo_cinza">
                Você pode vender, trocar ou doar o seu produto!
              </h2>
              <div className="button_anunciar">
                <Button
                  variant="success"
                  onClick={() => history.push("/create")}
                >
                  Anunciar agora
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel-item">
            <img
              src="../../../banner-header/banner2.jpg"
              className="banner-image"
              alt="banner-one"
            />
          </Carousel.Item>
        </Carousel>
      ) : (
        <Carousel fade controls={false} className="banner-header">
          <Carousel.Item className="carousel-item">
            <img
              src="../../../banner-header/banner1.jpg"
              className="banner-image"
              alt="banner-one"
            />
            <Carousel.Caption className="carousel-caption">
              <h2 className="titulo_fundo_cinza">
                Você pode vender, trocar ou doar o seu produto usado!
              </h2>
              <div className="button_anunciar">
                <Button
                  variant="success"
                  onClick={() => history.push("/create")}
                >
                  Anunciar agora
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className="carousel-item">
            <img
              src="../../../banner-header/banner2.jpg"
              className="banner-image"
              alt="banner-one"
            />
          </Carousel.Item>
        </Carousel>
      )}
    </>
  );
}

export default BannerHeader;
