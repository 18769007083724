import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAllUsersTalked } from "../../hooks/Chat/use-allUsersTalked";
import { useConnection } from "../../hooks/Chat/use-connection";
import { useMessageCurrent } from "../../hooks/Chat/use-messageCurrent";
import { useAuth } from "../../hooks/use-auth";
import { useKeyTab } from "../../hooks/use-keyTab";
import { createNewChat, getCurrentChat, getRecentChats } from "../../services/api-chat";
import { getItemPerId } from "../../services/api-item";
import BarLoading from "../Loading/BarLoading";
import ImageView from "./Images/ImageView";
import "./View.css";

function View({ productId }) {
  const [postContent, setPostContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState("");
  const history = useHistory();

  const { setKeyTab } = useKeyTab();
  const { addMessage, unsetNotifications, connections } = useConnection();
  const { setAllUsersTalked, allUsersTalked } = useAllUsersTalked();
  const { user } = useAuth();
  const { setInformationsOfUser, setMessagesFromDb, setPage } =
    useMessageCurrent();

  useEffect(() => {
    if (productId) {
      getItemPerId(productId)
        .then((response) => {
          setPrice(response.data.preco);
          setPostContent(response.data);
        })
        .catch((error) => console.log(error.message))
        .finally(setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateChatVendor = async () => {
    if (user) {
      const currentUser = allUsersTalked.find(
        (user) => user.otherUserId === postContent.vendedorId
      );

      if (
        postContent &&
        postContent.vendedorId !== user.id &&
        currentUser === undefined
      ) {
        try {
          const newChat = await createNewChat(postContent.vendedorId);

          setInformationsOfUser(newChat.data);

          if (connections) {
            await connections.invoke("JoinChat", newChat.data.otherUserId);
          }

          const recentChats = await getRecentChats();
          setAllUsersTalked(recentChats.data);
        } catch (error) {
          console.log(
            "It has a problem when creating a new chat " + error.message
          );
        } finally {
          setKeyTab("Chat");
          history.push(`/mypage/${user.id}`);
        }
      } else if (
        currentUser !== undefined &&
        postContent.vendedorId !== user.id
      ) {
        connections.invoke("JoinChat", currentUser.otherUserId);

        setInformationsOfUser(currentUser);
        unsetNotifications(currentUser.unreadMessages);
        addMessage(currentUser.id, [], "", true);

        await getCurrentChat(currentUser.id).then((response) => {
          setMessagesFromDb(response.data.items.reverse());
          setPage(response.data.pages);
        });

        setKeyTab("Chat");
        history.push(`/mypage/${user.id}`);
      }
    }
  };

  return (
    <div className="viewParentDiv">
      {loading ? (
        <div className="loading-mode">
          <BarLoading />
        </div>
      ) : (
        <>
          <ImageView images={postContent?.imagens} />
          <div className="rightSection">
            <div className="info-product">
              <div className="productDetails">
                <p>
                  {price !== "" &&
                    price.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                </p>
                <span>{postContent?.nome}</span>
              </div>
              <div className="contactDetails">
                <div className="info-seller">
                  <h4>Informações do Vendedor</h4>
                  {postContent?.aceitaTroca ? (
                    <Button variant="success" id="button-info-desktop">
                      Esse usuário aceita troca!
                    </Button>
                  ) : (
                    <Button variant="danger" id="button-info-desktop">
                      Esse usuário não aceita troca!
                    </Button>
                  )}
                </div>
                <p>
                  <span>Nome:</span> {postContent?.nomeVendedor}
                </p>
                {postContent?.mostrarContato && (
                  <p>
                    <span>Telefone:</span> {postContent?.contatoVendedor}
                  </p>
                )}
                {postContent?.aceitaTroca ? (
                  <Button variant="success" id="button-info-mobile">
                    Esse usuário aceita troca!
                  </Button>
                ) : (
                  <Button variant="danger" id="button-info-mobile">
                    Esse usuário não aceita troca!
                  </Button>
                )}
              </div>
            </div>
            <div className="productDescription">
              <h4>Descrição</h4>
              <p>{postContent?.descricao}</p>
              <div className="description-info">
                <div>
                  <p>
                    <span>Categoria:</span> {postContent?.subCategoria}
                  </p>
                  <p>
                    <span>Postado: </span>
                    {postContent?.postadoEm &&
                      moment(postContent?.postadoEm).format("DD/MM/YYYY")}
                  </p>
                </div>
                <Button
                  style={{ height: "50px" }}
                  size="lg"
                  onClick={handleCreateChatVendor}
                >
                  Iniciar Chat
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default View;
