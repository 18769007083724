import React from "react";
import BarLoading from "./BarLoading";

function BarLoadingFullPage() {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 100,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        top: 0,
      }}
    >
      <BarLoading
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-48%, -50%)",
        }}
      />
    </div>
  );
}

export default BarLoadingFullPage;
