import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAuth } from "../../hooks/use-auth";
import { getItemPerPendingUser, getItemPerUser } from "../../services/api-item";
import BarLoading from "../Loading/BarLoading";
import PostCards from "../PostCards/PostCards";
import "./UserPagePosts.css";

function UserPagePosts() {
  const { user } = useAuth();  
  const [postContentUser, setPostContentUser]  = useState([]);
  const [postContentUserPending, setPostContentUserPending] = useState([]);
  const [loading, setLoading] = useState(true);

  const isBigScreen = useMediaQuery({ query: "(min-width: 995px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 520px)" });

  useEffect(() => {
    if (user) {
      getItemPerUser(user)
        .then((response) => {
          setPostContentUser(response.data.itens);
        })
        .catch((error) => {
          console.log("GetItemPerUser: " + error.message);
        });

      getItemPerPendingUser()
        .then((response) => {
          setPostContentUserPending(response.data.itens);
        })
        .catch((error) => {
          console.log("GetPendingItems: " + error.message);
        })
        .finally(setLoading(false));
    }
  }, [user]);

  const menuCardsOfUser = postContentUser.map((product, index) => {
    return (
      <SwiperSlide key={index} className="fresh-recomendation-cards">
        <PostCards product={product} index={index} cardOfUser={true} />
      </SwiperSlide>
    );
  });

  const menuCardsOfPendingUser = postContentUserPending.map(
    (product, index) => {
      return (
        <SwiperSlide key={index} className="fresh-recomendation-cards">
          <PostCards
            product={product}
            index={index}
            pendingCard={true}
            cardOfUser={true}
          />
        </SwiperSlide>
      );
    }
  );

  return (
    <div className="container-cards-option">
      <div className="container-section">
        <h4>Seus itens</h4>
        {postContentUser.length > 0 ? (
          <div className="container">
            {loading ? (
              <BarLoading />
            ) : (
              <Swiper
                modules={[Pagination, FreeMode]}
                slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
                navigation
                pagination={{ clickable: true, dynamicBullets: true }}
                freeMode={true}
              >
                {menuCardsOfUser}
              </Swiper>
            )}
          </div>
        ) : (
          <p className="product-not-found">Nenhum produto encontrado.</p>
        )}
      </div>

      {postContentUserPending.length > 0 && (
        <div className="container-section">
          <h4
            style={{
              color: "#f2be0f",
            }}
          >
            Pendentes de Aprovação
          </h4>
          <div className="container">
            {loading ? (
              <BarLoading />
            ) : (
              <Swiper
                modules={[Pagination, FreeMode]}
                slidesPerView={isBigScreen ? 5 : isMobile ? 1 : 3}
                navigation
                pagination={{ clickable: true, dynamicBullets: true }}
                freeMode={true}
              >
                {menuCardsOfPendingUser}
              </Swiper>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserPagePosts;
