import React from "react";

import Signup from "../Components/Signup/Signup";

function SignupPage() {
  return (
    <>
      <Signup />
    </>
  );
}

export default SignupPage;
