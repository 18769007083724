import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import "bootstrap/dist/css/bootstrap.min.css";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useMemo, useState } from "react";
import ReactLoading from "react-loading";
import "./App.css";
import MainRoutes from "./Routes/MainRoutes";
import { useAllUsersTalked } from "./hooks/Chat/use-allUsersTalked";
import { useConnection } from "./hooks/Chat/use-connection";
import { useAuth } from "./hooks/use-auth";
import { getRecentChats } from "./services/api-chat";
import { getUser } from "./services/api-user";
import { host } from "./services/service";

function App() {
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useAuth();
  const { setAllUsersTalked, allUsersTalked } = useAllUsersTalked();
  const { addMessage, setConnection, setNotifications } = useConnection();

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    const getUserInfo = async (userId) => {
      await getUser(userId)
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => console.log(error.message));

      await getRecentChats()
        .then(async (response) => {
          setAllUsersTalked(response.data);
        })
        .catch((error) =>
          console.log("Error on getting users talked " + error.message)
        )
        .finally(setLoading(false));
    };

    if (token) {
      const decodedToken = jwtDecode(token);
      const userId = String(decodedToken.id);

      getUserInfo(userId);
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConnection = async (idUser) => {
    try {
      const connectionClient = new HubConnectionBuilder()
        .withUrl(`${host}/chatHub`)
        .configureLogging(LogLevel.Information)
        .build();

      await connectionClient.start();
      await connectionClient.invoke("ConnectOnLogin", idUser);

      connectionClient.on("ReceiveMessage", (info) => {
        addMessage(info.toChatId, info.content, info.senderName);
        if (info.senderName !== user.nome) setNotifications(1);
      });

      setConnection(connectionClient);
    } catch (error) {
      console.log("error joined in chat " + error.message);
    }
  };

  useMemo(() => {
    if (allUsersTalked) {
      let count = 0;

      allUsersTalked.forEach((user) => {
        count += user.unreadMessages;
      });
      setNotifications(count);
    }

    if (user) {
      handleConnection(user.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsersTalked]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(to right, #7fd082, #7ab67e, #4caf50)",
          }}
        >
          <ReactLoading type="spinningBubbles" height={90} width={90} />
        </div>
      ) : (
        <MainRoutes />
      )}
    </div>
  );
}

export default App;
