import { MessageCircleMore } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useConnection } from "../../hooks/Chat/use-connection";
import { useAuth } from "../../hooks/use-auth";
import { useKeyTab } from "../../hooks/use-keyTab";
import "./ChatFloatingButton.css";

function ChatFloatingButton() {
  const history = useHistory();

  const { user } = useAuth();
  const { setKeyTab } = useKeyTab();
  const { notifications } = useConnection();

  const [notificationCounter, setNotificationCounter] = useState(0);

  const handleChatRedirect = () => {
    setKeyTab("Chat");
    history.push(`/mypage/${user.id}`);
  };

  useEffect(() => {
    setNotificationCounter(notifications);
  }, [notifications]);

  return (
    <>
      {user ? (
        <div className="chat-btn-container" onClick={handleChatRedirect}>
          {notificationCounter > 0 && (
            <div className="notification-alert">
              <p>{notificationCounter > 99 ? 99 : notificationCounter}</p>
            </div>
          )}
          <MessageCircleMore color="white" size={33} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ChatFloatingButton;
