import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import "./Login.css"
import { loginAdmin } from "../../../services/api-admin";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AdminLogin = () => {
  const history = useHistory();

  const createUserFormSchema = z.object({
    email: z
      .string()
      .min(1, "O e-mail é obrigatório")
      .email("O e-mail esta invalido"),
    password: z.string().min(4, "A senha precisa de pelo menos 6 caracteres"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createUserFormSchema),
  });

  const onSubmit = (data) => {
    const email = data.email
    const password = data.password;
    loginAdmin(email, password)
      .then((response) => {
        localStorage.setItem("adminToken", String(response.data.token));
        history.push("/home-admin");
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally();
  }

  return (
    <div className="bg-custom root-login">
      <div className="form_container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="text-center mb-4">Faça seu login</h3>
          <div className="mb-3">
            <label htmlFor="email" className="mb-2">
              E-mail
            </label>
            <input
              type="email"
              placeholder="Digite seu e-mail"
              className={`form-control ${errors.email && "is-invalid"}`}
              {...register("email")}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email.message}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="mb-2">
              Senha
            </label>

            <input
              type="password"
              placeholder="Digite uma senha"
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              {...register("password")}
            />

            {errors.password && (
              <div className="invalid-feedback">{errors.password.message}</div>
            )}
          </div>

          <div className="d-grid mt-4">
            <button type="submit" className="btn bg-custom">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
