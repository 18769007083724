import { create } from "zustand";

export const useCategory = create((set) => ({
  womanPosts: [],
  menPosts: [],
  accessoriesPosts: [],
  booksPosts: [],
  eletronicPosts: [],

  setWomanPosts: (value) => set(() => ({ womanPosts: value })),
  setMenPosts: (value) => set(() => ({ menPosts: value })),
  setAccessoriesPosts: (value) => set(() => ({ accessoriesPosts: value })),
  setBooksPosts: (value) => set(() => ({ booksPosts: value })),
  setEletronicPosts: (value) => set(() => ({ eletronicPosts: value })),
}));
