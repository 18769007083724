import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { z } from "zod";
import { useAuth } from "../../hooks/use-auth";
import { createUser } from "../../services/api-user";
import "./Signup.css";

export default function Signup() {
  const { setUser } = useAuth();
  const history = useHistory();

  const showToastMessage = (message, type) => {
    if (type === true) {
      toast.success(`${message}`);
    } else {
      toast.error(`${message}`);
    }
  };

  const createUserFormSchema = z.object({
    nome: z.string().min(1, "Insira um nome válido!"),
    email: z
      .string()
      .min(1, "O e-mail é obrigatório")
      .email("O e-mail esta inválido")
      .refine((value) => value.includes("@edu.unifor.br"), {
        message:
          "Para garantirmos sua segurança o e-mail deverá ser o do aluno unifor.",
      }),
    matricula: z
      .string()
      .min(7, "A matrícula deve conter no mínimo 7 caracteres."),
    password: z.string().min(6, "A senha precisa de pelo menos 6 caracteres."),
    contato: z
      .string()
      .min(
        11,
        "Seu contato deve conter 11 caracteres. Não esqueça de incluir seu DDD!"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createUserFormSchema),
  });

  const onSubmit = (data) => {
    createUser(
      data.nome,
      data.email,
      data.matricula,
      data.password,
      data.contato
    )
      .then((response) => {
        setUser(response.data.user);
        localStorage.setItem("authToken", String(response.data.token));
        showToastMessage("Cadastro realizado com sucesso!", true);

        setTimeout(() => {
          history.push("/");
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          showToastMessage(`${error.response.data.Message}`);
        } else {
          showToastMessage(
            "Ops! Algum erro ocorreu ao realizar seu cadastro. Revise as informações e tente novamente!"
          );
        }
      });
  };

  return (
    <div className="signup-container">
      <div className="signupParentDiv">
        <div className="logo-signup">
          <img src="/Images/unifor-logo-rounded.png" height={80} alt="Logo do bazar universitário" />
          <h3>
            Bazar <br /> Universitário
          </h3>
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", gap: "1em" }}
        >
          <div>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              {...register("nome")}
              className={`${errors.nome && "is-invalid"}`}
            />
            {errors.nome && (
              <div className="invalid-feedback">{errors.nome.message}</div>
            )}
          </div>
          <div>
            <div className="info-email-unifor">
              <Form.Label>Email</Form.Label>
              <a
                href="https://uol.unifor.br/oul/UsuarioInserirEmail.do?method=ausenciaEmail"
                target="_blank" rel="noreferrer"
              >
                Não tem e-mail unifor? Crie um aqui!
              </a>
            </div>
            <Form.Control
              type="email"
              {...register("email")}
              className={`${errors.email && "is-invalid"}`}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email.message}</div>
            )}
          </div>
          <div>
            <Form.Label>Matrícula</Form.Label>
            <Form.Control
              type="number"
              {...register("matricula")}
              className={`${errors.matricula && "is-invalid"}`}
            />
            {errors.matricula && (
              <div className="invalid-feedback">{errors.matricula.message}</div>
            )}
          </div>
          <div>
            <Form.Label>Telefone</Form.Label>
            <InputMask
              type="text"
              maskChar={null}
              mask="(99) 99999-9999"
              placeholder="(99) 9999-9999"
              className={`form-control ${errors.matricula && "is-invalid"}`}
              {...register("contato")}
            />
            {errors.contato && (
              <div className="invalid-feedback">{errors.contato.message}</div>
            )}
          </div>
          <div>
            <Form.Label>Senha</Form.Label>
            <Form.Control
              type="password"
              {...register("password")}
              className={`${errors.password && "is-invalid"}`}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password.message}</div>
            )}
          </div>
          <Button type="submit" style={{ width: "100%" }}>
            Cadastrar
          </Button>
        </Form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
