import React from "react";
import "./Banner.css";
import BannerHeader from "./BannerHeader";

function Banner() {
  return (
    <div className="bannerParentDiv">
      <div className="menuBar">
        <ul className="otherQuickOptions">
          <a href="#others">Todas as Categorias</a>
          <a href="#menPosts">Roupas Masculinas</a>
          <a href="#womanPosts">Roupas Femininas</a>
          <a href="#accessories">Acessórios</a>
          <a href="#eletronic">Eletrônicos</a>
          <a href="#books">Livros</a>
        </ul>
      </div>
      <BannerHeader />
    </div>
  );
}

export default Banner;
