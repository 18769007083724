import { create } from "zustand";

export const useConnection = create((set, get) => ({
  connections: null,
  messages: {},
  notifications: 0,

  addMessage: (chatId, message, senderName, reset = false) =>
    set((state) => ({
      messages: {
        ...state.messages,
        [chatId]: !reset
          ? [...(state.messages[chatId] || []), { chatId, message, senderName }]
          : [],
      },
    })),
  getMessages: (chatId) => get().messages[chatId] || [],

  setConnection: (value) => set(() => ({ connections: value })),

  setNotifications: (value) =>
    set((state) => ({ notifications: state.notifications + value })),
  unsetNotifications: (value) =>
    set((state) => ({ notifications: state.notifications - value })),
}));
