import axios from "axios";
import { host } from "./service";

export const validateLogin = (email, password) => {
  const data = { email, password };

  return axios.post(`${host}/api/user/login`, data);
};

export const getUser = (userId) => {
  return axios({
    method: "get",
    url: `${host}/api/User/${userId}`,
  });
};

export const createUser = (
  nome,
  email,
  matricula,
  password,
  contato,
) => {
  const data = { nome, email, matricula, password, contato };

  return axios({
    method: "post",
    url: `${host}/api/User`,
    data: data,
    headers: { "Content-Type": "application/json" },
  });
};

export const modifyInformatioOfUser = (nome, email, foto) => {
  const data = { nome, email, foto };

  return axios({
    method: "put",
    data: data,
    url: `${host}/api/User`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteUser = (user) => {
  return axios.delete(`${host}/api/User/${user.id}`);
};
