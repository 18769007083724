import React from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useMediaQuery } from "react-responsive";
import ChatUserPage from "../Components/ChatUserPage/ChatUserPage";
import DeleteModal from "../Components/DeleteModal/DeleteModal";
import Header from "../Components/Header/Header";
import InfoUser from "../Components/InfoUser/InfoUser";
import UserPagePosts from "../Components/UserPagePosts/UserPagePosts";
import { useKeyTab } from "../hooks/use-keyTab";

function ViewUserInfo() {
  const { keyTab, setKeyTab } = useKeyTab();
  const isMobile = useMediaQuery({ query: "(max-width: 520px)" });

  return (
    <>
      <DeleteModal />
      <Header />
      <InfoUser />
      <div style={{ padding: "0.5em" }}>
        <Tabs activeKey={keyTab} onSelect={(k) => setKeyTab(k)} fill={isMobile}>
          <Tab
            eventKey="Anuncios"
            title="Anúncios"
            style={{ backgroundColor: "white" }}
          >
            <UserPagePosts />
          </Tab>
          <Tab
            eventKey="Chat"
            title="Chat"
            style={{ backgroundColor: "white" }}
          >
            <ChatUserPage />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default ViewUserInfo;
