import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { z } from "zod";
import { useModalDelete } from "../../hooks/use-modalDelete";
import { deleteItem, setSoldItemOfUser } from "../../services/api-item";
import "./DeleteModal.css";

function DeleteModal() {
  const { modalShow, setModalShow, productId, pendingCard } = useModalDelete();

  const createUserFormSchema = z.object({
    reason: z.string().refine((value) => value !== "notSelected", {
      message: "Selecione uma categoria antes de confirmar.",
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createUserFormSchema),
  });

  const onSoldItem = () => {
    setSoldItemOfUser(productId)
      .then(() => {
        showToastMessage(
          "Seu produto foi deletado com sucesso! Obrigado por usar nosso site!",
          true
        );
      })
      .catch((error) => {
        showToastMessage(
          "Tivemos um erro ao deletar seu produto! " + error.message
        );
      });
  };

  const onDeleteItem = () => {
    deleteItem(productId)
      .then(() => {
        showToastMessage("Seu produto foi deletado com sucesso!", true);
      })
      .catch((error) => {
        showToastMessage(
          "Tivemos um erro ao deletar seu produto! " + error.message
        );
      });
  };

  const onSubmit = async (data) => {
    try {
      if (data.reason === "sold") {
        await onSoldItem();
      } else {
        await onDeleteItem();
      }
    } catch (error) {
      showToastMessage(
        "Tivemos um erro ao deletar seu produto! " + error.message
      );
    } finally {
      setModalShow(false);
    }
  };

  const showToastMessage = (message, success) => {
    if (success === true) {
      toast.success(`${message}`);
    } else {
      toast.error(`${message}`);
    }
  };

  return (
    <div>
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="title">
            Você deseja deletar seu anúncio?
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <p>
              Ajude a nossa equipe nos dizendo o motivo de estar deletando seu
              anúncio!
            </p>
            <Form.Select
              {...register("reason")}
              className={`form-control ${errors.reason && "is-invalid"}`}
            >
              <option value="notSelected" className="option-description">
                Selecione o motivo
              </option>
              {!pendingCard && <option value="sold">Produto já vendido</option>}
              <option value="giveUp">Desistiu de vender</option>
              <option value="other">Outro</option>
            </Form.Select>
            {errors.reason && (
              <div className="invalid-feedback">{errors.reason.message}</div>
            )}
          </Modal.Body>
          <Modal.Footer className="btn-confirm">
            <Button variant="outline-danger" type="submit">
              Confirmar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default DeleteModal;
