import { Menu } from "lucide-react";
import { useState } from "react";
import { Button, Card, Image, Offcanvas } from "react-bootstrap";

function SidebarAdmin({setSelectedAdvert, adverts}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="outline-light" className="border" onClick={handleShow}>
        <Menu size={16} strokeWidth={3} />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Lista de anúncios</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="hidden-scroll">

        <div>
          { adverts && adverts.map(advert => (
            <Card key={advert.id} className="mb-2">
              <Card.Body className="p-2 pointer" style={{cursor: 'pointer'}} onClick={() => {setSelectedAdvert(advert)}}>
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <Image style={{width: '5rem'}} src={advert.images[0]} rounded />
                  </div>
                  <div className="ps-2 d-flex row">
                    <span className="fw-bold">{advert.title}</span>
                    <small className="text-secondary">{advert.name}</small>
                    <small className="text-secondary">{advert.date}</small>
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
          
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SidebarAdmin;