import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { useAllUsersTalked } from "../../hooks/Chat/use-allUsersTalked";
import { useAuth } from "../../hooks/use-auth";
import { useKeyTab } from "../../hooks/use-keyTab";
import Login from "../Login/Login";
import Search from "../Search/Search";
import "./Header.css";
import DropUserSection from "./components-header/DropUserSection";
import { CircleUserRound } from "lucide-react";

function Header() {
  const { user, setUser } = useAuth();
  const { setKeyTab } = useKeyTab();
  const { setAllUsersTalked } = useAllUsersTalked();
  const [modalShow, setModalShow] = useState(false);

  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const logoutHandler = () => {
    history.push("/");
    localStorage.removeItem("authToken");
    setUser(null);
    setAllUsersTalked([]);
  };

  const closeModalLogin = () => {
    setModalShow(false);
  };

  const chatHandle = () => {
    setKeyTab("Chat");
    history.push(`mypage/${user.id}`);
  };

  return (
    <div className="headerParentDiv">
      {!isMobile ? (
        <h5 className="logo">
          <img
            src="/Images/unifor-logo.png"
            width={50}
            onClick={() => history.push("/")}
            alt="logo"
          />{" "}
          Bazar Universitário
        </h5>
      ) : (
        <img
          src="/Images/unifor-logo.png"
          width={50}
          className="logo"
          onClick={() => history.push("/")}
          alt="logo"
        />
      )}

      <div className="productSearch">
        <Search />
      </div>

      <div className="right-container-position">
        {!user ? (
          <Button onClick={() => setModalShow(true)}>
            <CircleUserRound style={{ marginRight: "10px" }} />
            Entrar
          </Button>
        ) : (
          <div className="right-container">
            <DropUserSection
              onClickLogoutHandle={logoutHandler}
              onClickChatHandle={chatHandle}
              sizeDrop={isMobile ? "sm" : ""}
            />
            <Button
              variant="success"
              onClick={() => history.push("/create")}
              size={isMobile ? "sm" : ""}
            >
              Anunciar
            </Button>
          </div>
        )}
      </div>

      <Login show={modalShow} handleShow={closeModalLogin} />
    </div>
  );
}

export default Header;
