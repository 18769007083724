import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import HomeAdmin from "../Pages/admin/home-admin";
import LoginPage from "../Pages/admin/login";
import CreatePost from "../Pages/CreatePost";
import Home from "../Pages/Home";
import Signup from "../Pages/Signup";
import ViewMore from "../Pages/ViewMore";
import ViewPost from "../Pages/ViewPost";
import ViewUserInfo from "../Pages/ViewUserInfo";

function MainRoutes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/create">
          <CreatePost />
        </Route>
        <Route path="/viewmore">
          <ViewMore />
        </Route>
        <Route path="/admin">
          <LoginPage />
        </Route>
        <Route path="/home-admin">
          <HomeAdmin />
        </Route>
        <Route path="/viewpost/:productId">
          <ViewPost />
        </Route>
        <Route path="/mypage/:userId">
          <ViewUserInfo />
        </Route>
      </Switch>
    </Router>
  );
}

export default MainRoutes;
