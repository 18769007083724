import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "../../../hooks/use-auth";

function DropUserSection({ onClickLogoutHandle, sizeDrop, onClickChatHandle }) {
  const { user } = useAuth();

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-primary" size={sizeDrop}>
        {user.nome.split(" ")[0]}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href={`/mypage/${user?.id}`}>Minha Conta</Dropdown.Item>
        <Dropdown.Item href="/">Home</Dropdown.Item>
        <Dropdown.Item onClick={onClickChatHandle}>Chat</Dropdown.Item>
        <Dropdown.Item onClick={onClickLogoutHandle} style={{ color: "gray" }}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropUserSection;
