import styled from "styled-components";

export const MessageContainer = styled.div`
  border-radius: 8px;
  border: 1px solid rgb(45, 105, 224);
  position: relative;
  padding: 1em 0;
  margin-right: 1em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: ${(props) => (props.sidebar ? "auto" : "1.5em")};
  background-color: ${(props) => !props.sidebar && "rgb(45, 105, 224)"};
  animation: ${(props) => (props.sidebar ? "openSideBar 0.4s" : "closeSideBar 0.4s")};

  h4 {
    margin-left: 1em;
    display: ${(props) => (props.sidebar ? "block" : "none")};
    color: #0b5ed7;
  }

  @keyframes openSideBar {
    from {
      width: 0;
    }
    to {
      width: 19em;
    }
  }

  @keyframes closeSideBar {
    from {
      width: 19em;
    }
    to {
      width: 0;
    }
  }
`;

export const Content = styled.div`
  > svg {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 1.2em;
    display: block;
    z-index: 9999;
    border-radius: 100%;
    color: ${props => (props.sidebar ? "white" : "#0b5ed7")};;
    background-color: ${props => (props.sidebar ? "#0b5ed7" : "white")};
    left: ${props => (props.sidebar ? "16.5em" : "7px")};
    transform: ${props =>
      props.sidebar ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

export const Container = styled.div`
  background-color: white;
  height: 33em;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: ${props => (props.sidebar ? "19em" : "0")};
  animation: ${props => (props.sidebar && "openSideBar 0.6s")};

  @keyframes openSideBar {
    from {
      width: 0;
    }
    to {
      width: 19em;
    }
  }
`;
