import React from "react";
import "./Information.css";

function Information() {
  return (
    <>
      <div className="gradient-transition-top"></div>
      <div className="root-info">
        <h3>Entenda a importância de trocar/comprar roupas usadas!</h3>
        <div className="container-info-one">
          <div className="leaf-container">
            <img
              src="../../../Images/ecological.png"
              alt="ecological"
              width={250}
            />
          </div>
          <div className="info-one-description">
            <h4>Você sabia que...</h4>
            <p>
              A indústria da moda é responsável por
              <strong>
                {" "}
                8% da emissão de gás carbônico na atmosfera?
              </strong>{" "}
              Esse dado faz com que as mesmas fiquem classificadas como as
              segundas mais poluentes de todo o mundo, ficando atrás apenas do
              setor petrolífero!
            </p>
          </div>
        </div>
        <div className="container-info-two">
          <div className="climate-container">
            <img
              src="../../../Images/climate.png"
              alt="ecological"
              width={450}
            />
          </div>
          <div className="info-one-description">
            <h4>Fast Fashion & a Poluição</h4>
            <p>
              A produção têxtil além da produção de gás na atmosfera o qual
              contribui para o <strong>aquecimento global</strong>, há também
              uma grande variedade de produtos químicos tóxicos, como corantes,
              solventes, que podem contaminar o meio ambiente durante o processo
              de fabricação e acabamento. Além disso, a lavagem e descarte de
              roupas sintéticas podem liberar microplásticos no{"  "}
              <span>
                <strong>oceano</strong>
              </span>
              {"  "}
              contribuindo para a poluição marinha.
            </p>
          </div>
        </div>
        <div className="container-info-three">
          <div className="people-container">
            <img
              src="../../../Images/walking-people.jpg"
              alt="ecological"
              width={700}
            />
          </div>
          <div className="info-one-description">
            <h4>Vamos fazer a nossa parte!!</h4>
            <p>
              A ecologia é a mais importante em{" "}
              <strong>nosso mundo moderno!</strong> Não podemos ignorar os
              problemas que a humanidade criou, porém podemos atenuar a
              diminuição daquilo que prejudica nosso planeta.
            </p>
          </div>
        </div>
      </div>
      <div className="gradient-transition-bottom"></div>
    </>
  );
}

export default Information;
