import { ArrowRight } from "lucide-react";
import React, { useState } from "react";
import { ChatItem } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { useAllUsersTalked } from "../../hooks/Chat/use-allUsersTalked";
import { useConnection } from "../../hooks/Chat/use-connection";
import { useMessageCurrent } from "../../hooks/Chat/use-messageCurrent";
import { getCurrentChat } from "../../services/api-chat";
import "./ChatUserPage.css";
import ChatPrivate from "./components-chat/ChatPrivate";
import { Container, Content, MessageContainer } from "./styles";

function ChatUserPage() {
  const [showChat, setShowChat] = useState(true);

  const { allUsersTalked } = useAllUsersTalked();
  const {
    setInformationsOfUser,
    setMessagesFromDb,
    currentIdChat,
    setPage,
    currentIdUserExtern,
  } = useMessageCurrent();
  const { addMessage, unsetNotifications, connections } = useConnection();

  const handleChangeCurrentUser = async (chatId) => {
    if (currentIdChat !== chatId) {
      if (currentIdUserExtern && connections) {
        await connections.invoke("LeaveChat", currentIdUserExtern);
      }

      const currentUser = allUsersTalked.find((user) => user.id === chatId);

      if (connections) {
        await connections.invoke("JoinChat", currentUser.otherUserId);
      }

      await getCurrentChat(chatId).then((response) => {
        setMessagesFromDb(response.data.items.reverse());
        setPage(response.data.pages);
      });

      setInformationsOfUser(currentUser);
      unsetNotifications(currentUser.unreadMessages);
      addMessage(chatId, [], "", true);
      setShowChat(false);
    }
  };

  return (
    <div className="container-chat">
      <MessageContainer sidebar={showChat}>
        <h4>Mensagens</h4>
        <Content sidebar={showChat}>
          <ArrowRight
            onClick={() => setShowChat(!showChat)}
            size={25}
            style={{ padding: "3px" }}
          />
          <Container sidebar={showChat}>
            {allUsersTalked.length > 0 &&
              allUsersTalked.map((user) => {
                return (
                  <ChatItem
                    avatar="/user.png"
                    alt="avatar"
                    title={user.chatName}
                    subtitle={""}
                    key={user.id}
                    onClick={() => handleChangeCurrentUser(user?.id)}
                  />
                );
              })}
          </Container>
        </Content>
      </MessageContainer>
      <ChatPrivate />
    </div>
  );
}

export default ChatUserPage;
