import { Search as SearchIcon } from "lucide-react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useAllPosts } from "../../hooks/use-posts";
import "./search.css";
import { getItemPerPage } from "../../services/api-item";
import { useLoading } from "../../hooks/use-loading";

function Search() {
  const [filterValue, setFilterValue] = useState()
  const { setLoadingPage } = useLoading();
  const { setAllPosts } = useAllPosts();
  const history = useHistory();

  const search = () => {
    setLoadingPage(true)
    getItemPerPage(1, 20, filterValue).then(({ data })=> {
      setAllPosts(data.items ?? [])
      history.push('/viewmore')
      setLoadingPage(false)
    }).catch(err => {
      alert("Falha ao buscar por item")
      console.error(err)
    })
  }

  return (
    <div className="search-property-container">
      <div className="searchInputs">
        <input
          type="text"
          placeholder="Encontre roupas masculinas, femininas, eletrônicos..."
          value={filterValue}
          onKeyPress={(event) => {
            if (event.key === 'Enter') search()
          }}
          onChange={(event) => {
            setFilterValue(event.target.value)
          }}
        />
        <div className="searchIcon">
          <div onClick={search} className="search-icon-div">
            <SearchIcon size={30} className="search-property" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
