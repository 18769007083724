import React from "react";
import "./BarLoading.css";
import ReactLoading from "react-loading";
import { Leaf } from "lucide-react";

function BarLoading({ title, classStyle = "" }) {
  return (
    <div className={classStyle}>
      <div className="loading-position">
        <ReactLoading type="spin" height={70} color="#61ff66" width={70} />
        <Leaf className="leaf-position" />
      </div>
      <p style={{ textAlign: "center", color: "#61ff66" }}>
        {title && title}
      </p>
    </div>
  );
}

export default BarLoading;
