import { zodResolver } from "@hookform/resolvers/zod";
import { Plus, Trash } from "lucide-react";
import React, { useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { z } from "zod";
import { useAuth } from "../../hooks/use-auth";
import { useKeyTab } from "../../hooks/use-keyTab";
import { createProduct } from "../../services/api-item";
import CarouselsImages from "../admin/home/carrousell";
import BarLoadingFullPage from "../Loading/BarLoadingFullPage";
import "./Create.css";
import CreateModal from "./CreateModal/CreateModal";
import NotLogedModal from "./CreateModal/NotLoggedModal";
import BlankImage from "./default-image.png";

const Create = () => {
  const { setKeyTab } = useKeyTab();
  const { user } = useAuth();
  const history = useHistory();
  const fileRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [modalNotLoggedUser, setModalNotLoggedUser] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesRef, setImagesRef] = useState([]);

  const createUserFormSchema = z.object({
    nome: z.string().min(1, "Insira um nome válido!"),
    subCategoria: z.string().refine((value) => value !== "Categoria do Item", {
      message: "Selecione uma categoria.",
    }),
    preco: z.coerce
      .number({
        required_error: "Insira um valor válido!",
      })
      .positive({ message: "Insira um valor válido!" }),
    descricao: z
      .string()
      .min(1, "A descrição do item é obrigatória.")
      .max(250, "Escreva uma descrição de no máximo 250 caracteres."),
    change: z.boolean(),
    contact: z.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createUserFormSchema),
  });

  const showToastMessage = (message) => {
    toast.warning(`${message}`);
  };

  const onSubmit = (data) => {
    if (imagesRef.length < 1) {
      showToastMessage(
        "A adição de fotos é obrigatória! Adicione no mínimo 1 foto para anunciar o seu produto."
      );
    } else {
      if (user) {
        setLoading(true);

        createProduct(
          data.nome,
          data.descricao,
          data.preco,
          data.change,
          imagesRef,
          data.subCategoria,
          data.contact
        )
          .then(() => setShow(true))
          .catch((error) =>
            console.error("created product error!" + error.message)
          );
      } else {
        setModalNotLoggedUser(true);
      }
    }
  };

  const handleCloseModal = () => {
    setShow(false);
    setKeyTab("Anuncios");
    history.push(`mypage/${user.id}`);
  };

  const handleChangeImage = (event) => {
    if (images.length >= 3) return;
    let imagesURL = images;
    let imagesBLOB = imagesRef;
    let imageList = event.target.files;
    for (let i = 0; i < imageList.length; i++) {
      if (imagesURL.length < 3) {
        const image = imageList[i];
        imagesURL = [...imagesURL, URL.createObjectURL(image)];
        imagesBLOB = [...imagesBLOB, image];
      }
    }
    setImages(imagesURL);
    setImagesRef(imagesBLOB);
    fileRef.current.value = "";
  };

  return (
    <div className="container-create">
      {loading ? (
        <BarLoadingFullPage />
      ) : (
        <div className="container-form">
          <h2>Anunciar</h2>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", gap: "1em", flexDirection: "column" }}
          >
            <Container>
              <Row>
                <Col sx={12} md={6}>
                  <Form.Group controlId="itemImages">
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleChangeImage}
                      hidden
                      ref={fileRef}
                      accept="image/*"
                    />
                    <div className="d-flex justify-content-between pt-2">
                      <Form.Label>Adiciona uma imagem:</Form.Label>
                      <div>
                        <Button
                          className="me-1"
                          variant={"danger"}
                          size="sm"
                          onClick={() => {
                            setImages([]);
                            setImagesRef([]);
                          }}
                        >
                          <Trash size={20} />
                        </Button>
                        {images.length < 3 && (
                          <Button
                            variant={"success"}
                            size="sm"
                            onClick={() => {
                              fileRef.current.click();
                            }}
                          >
                            <Plus size={20} />
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                  <div className="mt-3">
                    <CarouselsImages
                      imagesList={images}
                      blankImage={BlankImage}
                    />
                  </div>
                </Col>
                <Col sx={12} md={6}>
                  <Form.Group>
                    <div className="mt-1">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        className={`form-control ${
                          errors.nome && "is-invalid"
                        }`}
                        {...register("nome")}
                      />
                      {errors.nome && (
                        <div className="invalid-feedback">
                          {errors.nome.message}
                        </div>
                      )}
                    </div>
                    <div className="mt-1">
                      <Form.Label>Categoria</Form.Label>
                      <Form.Select
                        {...register("subCategoria")}
                        className={`form-control ${
                          errors.subCategoria && "is-invalid"
                        }`}
                      >
                        <option>Categoria do Item</option>
                        <option value="Roupas Masculinas">
                          Roupas Masculinas
                        </option>
                        <option value="Roupas Femininas">
                          Roupas Femininas
                        </option>
                        <option value="Acessorios Bolsas">Bolsas</option>
                        <option value="Acessorios Bijouteria">
                          Bijouteria
                        </option>
                        <option value="Eletronicos">Eletrônicos</option>
                        <option value="Instrumentos">Instrumentos</option>
                        <option value="Artigos Casa">Artigos de Casa</option>
                        <option value="Livros">Livros</option>
                      </Form.Select>
                      {errors.subCategoria && (
                        <div className="invalid-feedback">
                          {errors.subCategoria.message}
                        </div>
                      )}
                    </div>
                    <div className="mt-1">
                      <Form.Label>Preço</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>R$</InputGroup.Text>
                        <CurrencyInput
                          decimalsLimit={2}
                          className={`form-control ${
                            errors.preco && "is-invalid"
                          }`}
                          onValueChange={(value, name, values) =>
                            setValue("preco", values.float)
                          }
                        />
                        {errors.preco && (
                          <div className="invalid-feedback">
                            {errors.preco.message}
                          </div>
                        )}
                      </InputGroup>
                    </div>
                    <div className="mt-1">
                      <Form.Label>Descrição</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={4}
                        cols={50}
                        style={{ resize: "none" }}
                        {...register("descricao")}
                        className={`form-control ${
                          errors.descricao && "is-invalid"
                        }`}
                      />
                      {errors.descricao && (
                        <div className="invalid-feedback">
                          {errors.descricao.message}
                        </div>
                      )}
                    </div>
                    <div className="mt-1">
                      <Form.Label>Troca</Form.Label>
                      <div class="input-group mb-3">
                        <div class="input-group-text">
                          <input
                            class="form-check-input mt-0"
                            type="checkbox"
                            {...register("change", {})}
                          />
                        </div>
                        <Form.Control
                          value={"Aceito trocar por outro item!"}
                          disabled
                        />
                      </div>
                      {errors.aceitaTroca && (
                        <div className="invalid-feedback">
                          {errors.aceitaTroca.message}
                        </div>
                      )}
                    </div>
                    <div className="mt-1">
                      <Form.Label>Contato</Form.Label>
                      <div class="input-group mb-3">
                        <div class="input-group-text">
                          <input
                            class="form-check-input mt-0"
                            type="checkbox"
                            value=""
                            {...register("contact", {})}
                          />
                        </div>
                        <Form.Control
                          value={"Desejo mostrar meu contato no anúncio!"}
                          disabled
                        />
                      </div>
                      {errors.mostrarContato && (
                        <div className="invalid-feedback">
                          {errors.mostrarContato.message}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Container>
            <Button type="submit">Anunciar</Button>
          </Form>
        </div>
      )}

      <CreateModal show={show} handleClose={handleCloseModal} />
      <NotLogedModal
        show={modalNotLoggedUser}
        handleClose={() => setModalNotLoggedUser(false)}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Create;
