import { create } from "zustand";

export const useMessageCurrent = create((set) => ({
  currentUserName: null, // Name of my current user in my private
  currentIdUserExtern: null, // Id of my user
  currentIdChat: null, // Id of my chat
  messagesFromDb: [], // Getting old messages to show
  lastMessageTitle: "", // Last message receive or sent
  page: 1, // Page of Past Message

  setInformationsOfUser: (info) =>
    set(() => ({
      currentIdChat: info.id,
      currentUserName: info.chatName,
      currentIdUserExtern: info.otherUserId,
      lastMessageTitle: info.lastMessage,
    })),

  setPage: (value) => set(() => ({ page: value })),

  setCurrentUserName: (value) => set(() => ({ currentUserName: value })),
  setCurrentIdUserExtern: (value) =>
    set(() => ({ currentIdUserExtern: value })),
  setCurrentIdChat: (value) => set(() => ({ currentIdChat: value })),
  setMessagesFromDb: (value) =>
    set(() => ({
      messagesFromDb: value,
    })),
  setLasUserMessageTitle: (value) => set(() => ({ lastMessageTitle: value })),
}));
