import React from "react";
import "./ImageView.css";
import { Card, Carousel } from "react-bootstrap";
import Image from "react-bootstrap/Image";

function ImageView({ images }) {
  return (
    <Card body className="image-card">
      <Carousel variant="dark" fade className="carousel-content">
        {images && images.map((image) => {
          return (
            <Carousel.Item
              className="rounded p-3"
              style={{
                backgroundColor: "#ededed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "min-content",
              }}
              key={image.index}
            >
              <Image className="carousel-image-size" src={image.url} key={image.id} rounded />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Card>
  );
}

export default ImageView;
